import './produce.css';

function Produce() {

    return(
        <section id='sec4' className='produce sec'>
            <div className='produce__container'>
            <div className='produce__top'>
            <h2 className='produce__title'>Производство</h2>
            <div className='produce__desc'>
                <p className='produce__desc_paragraph'>
                Производство и продажа бумажных мешков - основное направление деятельности ООО ПКФ "Легион Пром". Компания располагает собственной производственной базой по изготовлению бумажных мешков, а также современным флексопечатным оборудованием для индивидуализации упаковки.
                </p>
                <p className='produce__desc_paragraph'>
                Многолетний опыт работы ООО ПКФ «Легион Пром» позволяет гарантировать выполнение индивидуальных заказов крафт мешков и пакетов любой степени сложности.
                </p>
            </div>

            </div>
            <div className='produce__bottom'>
                <div className='produce__bottom_item'>Экологически чистая упаковка</div>
                <div className='produce__bottom_item'>Соответствие ГОСТ</div>
                <div className='produce__bottom_item'>Высокое качество продукции</div>
            </div>
            </div>

        </section>
    )
}

export default Produce;