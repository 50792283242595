import './manufacture.css';
import firstImg from '../../img/man1.png';
import secondImg from '../../img/man2.png';
import thirdImg from '../../img/man3.png';

function Manufacture() {

    return (
        <section id='sec2' className='manufacture sec'>
            <div className='manufacture__container'>
                <div className='manufacture__item'>
                    <div className='manufacture__header'>
                        <img className='manufacture__img' src={firstImg} alt='иконка продукция' />
                        <h2 className='manufacture__title'>Продукция</h2>
                    </div>

                    <div className='manufacture__desc'>
                    Бумажные мешки и пакеты от
                    ООО ПКФ "Легион Пром"
                    используются для фасовки комбикормов и биодобавок, древесного угля, сухих строительных смесей и т.д. Экологически чистая упаковка применяется во многих отраслях промышленного и пищевого производства, а также торговли и сельского хозяйства. Мы предоставляем исключительно высокое качество изготавливаемой продукции.
                    </div>
                </div>

                <div className='manufacture__item'>
                    <div className='manufacture__header'>
                        <img className='manufacture__img' src={secondImg} alt='иконка сотрудничество' />
                        <h2 className='manufacture__title'>Сотрудничество</h2>
                    </div>

                    <div className='manufacture__desc'>
                    Мы ставим своей задачей долгосрочные и взаимовыгодные деловые отношения с каждым клиентом. Мы предоставляем полный цикл сотрудничества, начиная с момента обращения в компанию, и заканчивая изготовлением и доставкой заказа.
                    </div>
                </div>

                <div className='manufacture__item'>
                    <div className='manufacture__header'>
                        <img className='manufacture__img' src={thirdImg} alt='иконка контроль' />
                        <h2 className='manufacture__title'>Контроль</h2>
                    </div>

                    <div className='manufacture__desc'>
                    Вся продукция ООО ПКФ "Легион Пром" подвергается приемо-сдаточным испытаниям, подтверждающим их соответствие ГОСТ. 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Manufacture;