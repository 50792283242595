import './intro.css';
import intro1 from '../../img/intro1.jpg';
import intro2 from '../../img/intro2.jpg';
import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
function Intro() {

    useEffect(() => {
        let images = gsap.utils.toArray(".intro__container"),
            tl = gsap.timeline({repeat: -1}),
            fadeDuration = 1.5,
            stayDuration = 3;

        // show the first one
        gsap.set(images[0], {autoAlpha: 1});

        // fade each one in successively (in a staggered fashion) EXCEPT the first one (because it's already visible)
        tl.to(images.slice(1), {delay: stayDuration, autoAlpha: 1, duration: fadeDuration, stagger: stayDuration + fadeDuration})
        // hide each one after the next one finishes fading in on top of it. Exclude the final image because we'll handle the crossfade with the first image with a tween at the end.
        .to(images.slice(0, images.length-1), {autoAlpha: 0, duration:0.01, stagger: stayDuration + fadeDuration}, stayDuration + fadeDuration)
        // show the first image (but it won't be visible yet because the last image is on top of it)
        .set(images[0], {autoAlpha: 1})
        // now fade out the last image so that the first one is showing again
        .to(images[images.length-1], {autoAlpha: 0, duration: fadeDuration}, "+=" + stayDuration);
    }, [])

    return(
        <section id='sec0' className="intro sec">

            <div className='intro__container'>
                <img className='intro__bg' src={intro1} alt='интро'></img>
                <h2 className='intro__title'>
                КАЧЕСТВО. <br/>
                НАДЕЖНОСТЬ. <br/>
                СОТРУДНИЧЕСТВО.
                </h2>
            </div>
            <div className='intro__container'>
                <img className='intro__bg intro__bg_second' src={intro2} alt='интро'></img>
                <h2 className='intro__title'>
                    РЕАЛИЗОВЫВАЕМ <br/>
                    ЛЮБЫЕ <br/>
                    ДИЗАЙНЕРСКИЕ <br/>
                    РЕШЕНИЯ
                </h2>
            </div>
        </section>
    )
}

export default Intro;