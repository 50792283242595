import './products.css';
import bag1 from '../../img/bag1.jpg';
import bag2 from '../../img/bag2.jpg';
import bag3 from '../../img/bag3.jpg';
import { gsap } from 'gsap';

function Products() {

    function openModal() {
        const tl = gsap.timeline()
    //    tl.to('.modal', {display: 'block'})
    //     .to('.modal__overlay', {opacity: 0.8, duration: 0.3})
    //     .to('.modal__container', {opacity: 1, duration: 0.2})
        gsap.to('.modal', {display: 'block', duration: 0});
        gsap.to('.modal__overlay', {opacity: 0.8, duration: 0.2})
        gsap.to('.modal__container', {opacity: 1, duration: 0.2})

    }

    return (
        <section id='sec5' className='products sec'>
            <div className='products__container'>
                <h2 className='products__title'>Продукция</h2>
                <div className='products__desc'>ООО ПКФ «Легион Пром» производит исключительно высококачественную <br/> продукцию, соответствующую нормам ГОСТ</div>

                <div className='products__items'>
                    <div className='products__item'>
                        <div className='products__item_top'>
                            <div className='products__pic'>
                                <img className='products__img' src={bag1} alt='мешок для фасовки угля' />
                            </div>
                            <div className='products__name'>Для фасовки угля</div>
                        </div>
                        <button className='products__btn' onClick={openModal}>Заказать</button>
                    </div>

                    <div className='products__item'>
                        <div className='products__item_top'>
                            <div className='products__pic'>
                                <img className='products__img' src={bag2} alt='мешок для фасовки цемента'  />
                            </div>
                            <div className='products__name'>Для фасовки цемента</div>
                        </div>
                        <button className='products__btn' onClick={openModal}>Заказать</button>
                    </div>

                    <div className='products__item'>
                        <div className='products__item_top'>
                            <div className='products__pic'>
                                <img className='products__img' src={bag3} alt='иконка для фасовки пищевой продукции' />
                            </div>
                            <div className='products__name'>Для фасовки продуктов</div>
                        </div>
                        <button className='products__btn' onClick={openModal}>Заказать</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products;