import './about.css';
import check from '../../img/check1.png'

function About() {

    return (
        <section id='sec1' className='about sec'>
            <div className='about__container'>
                <div className='about__left'>
                    <h2 className='about__header'>Наша компания —</h2>
                    <div className='about__desc'>
                        производитель бумажных мешков <br/>
                        и пакетов для упаковки <br/>
                        промышленных и потребительских <br/>
                        товаров из прочной экологически чистой упаковки <br/>
                    </div>
                </div>

                <div className='about__right'>
                    <ul className='about__list'>
                        <li className='about__list_item'>
                            <img className='about__list_icon' src={check}/>
                            для цемента и сухих строительных смесей
                            </li>
                        <li className='about__list_item'>
                        <img className='about__list_icon' src={check}/>
                            для древесного угля
                            </li>
                        <li className='about__list_item'>
                            <img className='about__list_icon' src={check}/>
                            для продуктов питания
                            </li>
                        <li className='about__list_item'>
                            <img className='about__list_icon' src={check}/>
                            для семян и удобрений
                            </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default About;