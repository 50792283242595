import './App.css';
import Intro from './components/intro/Intro';
import About from './components/about/About';
import Header from './components/header/Header';
import Manufacture from './components/manufacture/Manufacture';
import Examples from './components/examples/Examples';
import Produce from './components/produce/produce';
import Products from './components/products/products';
import Contacts from './components/contacts/Contacts';
import Modal from './components/modal/Modal';
import BurgerMenu from './components/burgerMenu/BurgerMenu';
import { useEffect, useRef, useState } from 'react';


function App() {

  const appRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [activeLink, setActiveLink] = useState(null);
  const isYandexBrowser = /YaBrowser/i.test(navigator.userAgent);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    const app = document.querySelector('.App');
    if(isYandexBrowser) {
      app.style.scrollSnapType = 'y mandatory';
      app.style.overflowY = 'scroll';
      document.querySelector('.intro').style.scrollSnapAlign = 'start';
      document.querySelector('.about').style.scrollSnapAlign = 'start';
      document.querySelector('.manufacture').style.scrollSnapAlign = 'start';
      document.querySelector('.manufacture').style.paddingTop = '100px';
      document.querySelector('.products').style.paddingTop = '100px';
      document.querySelector('.about').style.paddingTop = '0px';
      document.querySelector('.manufacture').style.height = '85vh';
      document.querySelector('.examples').style.height = '85vh';
      document.querySelector('.products').style.height = '85vh';
      document.querySelector('.examples').style.scrollSnapAlign = 'start';
      document.querySelector('.produce').style.scrollSnapAlign = 'start';
      document.querySelector('.products').style.scrollSnapAlign = 'start';
      document.querySelector('.contacts').style.scrollSnapAlign = 'start';
    }

    if(isTouchDevice || window.innerWidth < 800) {
      app.style.scrollSnapType = 'y mandatory';
      app.style.overflowY = 'scroll';
      document.querySelector('.intro').style.scrollSnapAlign = 'start';
      document.querySelector('.about').style.scrollSnapAlign = 'start';
      document.querySelector('.manufacture').style.scrollSnapAlign = 'start';
      document.querySelector('.examples').style.scrollSnapAlign = 'start';
      document.querySelector('.produce').style.scrollSnapAlign = 'start';
      document.querySelector('.products').style.scrollSnapAlign = 'start';
      document.querySelector('.contacts').style.scrollSnapAlign = 'start';
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if(!isYandexBrowser || !isTouchDevice) {
      const scrollTop = appRef.current.scrollTop;
      const scrollHeight = appRef.current.scrollHeight - appRef.current.clientHeight;
      const newScrollPercentage = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(newScrollPercentage)
      }
    };


    // Add event listener to track scroll position
    // appRef.current.addEventListener('wheel', handleScroll);
    if(!isYandexBrowser || !isTouchDevice) {
      appRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Cleanup the event listener
      // appRef.current.removeEventListener('wheel', handleScroll);
      appRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(scrollPercentage > 0 && scrollPercentage < 50) {
      setActiveLink('1');
    } else if (scrollPercentage >= 50 && scrollPercentage < 67) {
      setActiveLink('2');
    } else if (scrollPercentage >= 67 && scrollPercentage < 83) {
      setActiveLink('3')
    } else if (scrollPercentage >= 83) {
      setActiveLink('4');
    } else {
      setActiveLink(null)
    }
  }, [scrollPercentage])

  const handleScroll = (e) => {
    if(!isYandexBrowser || !isTouchDevice) {
      const container = appRef.current;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
    
      if (scrollTop === 0 && e.deltaY < 0) {
        // Reached the top of the container and scrolling up
        return;
      }
    
      if (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0) {
        // Reached the bottom of the container and scrolling down
        return;
      }
    
      const delta = e.deltaY;
    
      if (delta > 0) {
        // Scrolling down
        scrollToNextSection('down');
      } else if (delta < 0) {
        // Scrolling up
        scrollToNextSection('up');
      }
    }
  };

  const scrollToNextSection = (direction) => {
    const container = appRef.current;
    const scrollHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const clientHeight = container.clientHeight;
    const sectionHeight = Math.ceil(scrollHeight / 7); // Adjust the division factor as needed
    const scrollDistance = clientHeight * 0.7; // Adjust the scroll distance factor as needed
    
    let nextSectionIndex;
    if (direction === 'down') {
      nextSectionIndex = Math.ceil((scrollTop + scrollDistance) / sectionHeight);
    } else if (direction === 'up') {
      nextSectionIndex = Math.floor((scrollTop - scrollDistance) / sectionHeight);
    }
  
    container.scrollTo({
      top: nextSectionIndex * sectionHeight,
      behavior: 'smooth',
    });
  };


  return (
    <div className="App" ref={appRef} onScroll={handleScroll} onWheel={handleScroll}>
      <Header activeLink={activeLink}/>
      <Intro/>
      <About/>
      <Manufacture/>
      <Examples/>
      <Produce/>
      <Products/>
      <Contacts/>
      <Modal/>
      <BurgerMenu/>
    </div>
  );
}

export default App;
