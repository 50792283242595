import './header.css';
import logo from '../../img/logo.png';
import { gsap } from 'gsap';
import { useEffect, useState } from 'react';
function Header({activeLink}) {
 

    function handleScroll(e) {
       const elem = document.getElementById(`${e.target.getAttribute('data-to')}`);
       elem.scrollIntoView({behavior: 'smooth'})
    }

    function handleOpenMenu() {
        gsap.set('.burger-menu', {display: 'block'})
        gsap.to('.burger-menu', {
            x: '0',
            duration: 0.5
        })
    }

    return (
        <header className='header'>
            <div className='header__container'>
                <div className='header__logo'>
                    <img className='header__img' src={logo} alt='Легион промо логотип'/>
                    <div className='header__title'>Легион Пром</div>
                </div>

                <div className='header__links'>
                    <div data-to='sec1' className={`header__link ${activeLink === '1' ? 'active-link' : null}`} onClick={handleScroll}>О компании</div>
                    <div data-to='sec4' className={`header__link ${activeLink === '2' ? 'active-link' : null}`} onClick={handleScroll}>Производство</div>
                    <div data-to='sec5' className={`header__link ${activeLink === '3' ? 'active-link' : null}`} onClick={handleScroll}>Продукция</div>
                    <div data-to='sec6' className={`header__link ${activeLink === '4' ? 'active-link' : null}`} onClick={handleScroll}>Контакты</div>
                </div>

                <div className='header__burger' onClick={handleOpenMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default Header;