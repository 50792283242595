import './contacts.css';

function Contacts() {

    return (
        <section id='sec6' className='contacts sec'>
            <div className='contacts__container'>
                <div className='contacts__left'>
                    <h2 className='contacts__title'>Контактная информация:</h2>
                    <div className='contacts__info'>
                        <p className='contacts__info_item'>г. Челябинск, ул. Складская, д. 1</p>
                        <p className='contacts__info_item'>
                            <a className='contacts__link' href='tel:+78003336345'>8 (800) 333-63-45</a>
                        </p>
                        <p className='contacts__info_item'>
                            <a className='contacts__link' href='tel:+73512408500'>8 (351) 240-85-00</a>
                        </p>
                        <p className='contacts__info_item'>Пн-Пт, 9:00 - 17:00</p>
                        <p className='contacts__info_item'>
                            E-mail: 
                            <a className='contacts__mail contacts__link' href='mailto:info@legionprom.com'>info@legionprom.com</a>
                        </p>
                    </div>
                </div>

                <div className='contacts__right'>
                <div style={{position: 'relative', overflow: 'hidden'}}>
                    <a href="https://yandex.ru/maps/org/pkf_legion_prom/111578542411/?utm_medium=mapframe&utm_source=maps" 
                        style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0px'}}>ПКФ Легион Пром</a>
                    <a href="https://yandex.ru/maps/56/chelyabinsk/category/packing_materials/184106936/?utm_medium=mapframe&utm_source=maps" 
                        style={{color: '#eee', fontSize: '12px', position: 'absolute', top:'14px'}}>Тара и упаковочные материалы в Челябинске</a>
                        <iframe className='contacts__map' src="https://yandex.ru/map-widget/v1/?ll=61.470897%2C55.224198&mode=search&oid=111578542411&ol=biz&z=16.75"  allowFullScreen={true} style={{position: 'relative'}}></iframe></div>
                </div>
            </div>
        </section>
    )
}

export default Contacts;