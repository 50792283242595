import './examples.css';
import firstPic from '../../img/ex1.png';
import secondPic from '../../img/ex2.png';
import thirdPic from '../../img/ex3.png';
import bag1 from '../../img/bag1.jpg';
import bag2 from '../../img/bag2.jpg';
import bag3 from '../../img/bag3.jpg';

function Examples() {

    return(
        <section id='sec3' className='examples sec'>
            <div className='examples__container'>
                <div className='examples__item'>
                    <div className='examples__header examples__header_first'>
                        <img className='examples__img' src={firstPic} alt='иконка для фасовки угля' />
                        <h2 className='examples__title'>Для фасовки <br/> угля</h2>
                    </div>
                    <div className='examples__desc'>
                            <img className='examples__bag' src={bag1} alt='мешок для фасовки угля' />
                    </div>
                </div>

                <div className='examples__item'>
                    <div className='examples__header'>
                        <img className='examples__img' src={secondPic} alt='иконка для фасовки цемента' />
                        <h2 className='examples__title'>Для фасовки <br/> цемента</h2>
                    </div>
                    <div className='examples__desc'>
                        <img className='examples__bag' src={bag2} alt='мешок для фасовки цемента' />
                    </div>
                </div>

                <div className='examples__item'>
                    <div className='examples__header'>
                        <img className='examples__img' src={thirdPic} alt='иконка для фасовки пищевой продукции' />
                        <h2 className='examples__title'>Для фасовки пищевой <br/> продукции</h2>
                    </div>
                    <div className='examples__desc'>
                            <img className='examples__bag' src={bag3} alt='мешок для фасовки пищевой продукции' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Examples;