import './burgerMenu.css';
import cross from '../../img/cross.svg';
import arrow from '../../img/arrow.svg';
import { gsap } from 'gsap';
import { useEffect } from 'react';

function BurgerMenu() {

    useEffect(() => {
        gsap.set('.burger-menu', {x: '100%'})
    }, [])

    function handleScroll(e) {
        //    console.log(e.target.getAttribute('data-to'))
           const elem = document.getElementById(`${e.target.getAttribute('data-to')}`);
           elem.scrollIntoView({behavior: 'smooth'});
           handleCloseMenu()
    }

    function handleCloseMenu() {
        gsap.to('.burger-menu', {
            x: '100%',
            duration: 0.5
        })
    }

    return(
        <div className="burger-menu">
        <div className="burger-menu__close_container">
            <img className="burger-menu__close" src={cross} alt="close menu" onClick={handleCloseMenu} />
        </div>
    <ul className="burger-menu__list">
        <li className="burger-menu__item">
            <img className="burger-menu__item-arrow" src={arrow} alt="go to"/>
            <a className="burger-menu__link" data-to='sec1' onClick={handleScroll}>О компании</a>
        </li>
        <li className="burger-menu__item">
            <img className="burger-menu__item-arrow" src={arrow} alt="go to"/>
            <a className="burger-menu__link" data-to='sec4' onClick={handleScroll}>Производство</a>
        </li>
        <li className="burger-menu__item">
            <img className="burger-menu__item-arrow" src={arrow} alt="go to"/>
            <a className="burger-menu__link" data-to='sec5' onClick={handleScroll}>Продукция</a>
        </li>
        <li className="burger-menu__item">
            <img className="burger-menu__item-arrow" src={arrow} alt="go to"/>
            <a className="burger-menu__link" data-to='sec6' onClick={handleScroll}>Контакты</a>
        </li>
    </ul>
    </div>
    )
}

export default BurgerMenu;