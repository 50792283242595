import './modal.css';
import { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { gsap } from 'gsap';

function Modal() {
    const form = useRef();

    useEffect(() => {
        gsap.set(['.modal__overlay', '.modal__container'], {opacity: 0})
    }, [])

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1u8x5kq', 'template_xj7oyzk', form.current, 'rQMB5frFdqFPpTKqT')
        e.target.reset();
      };

    function closeOverlay() {
        const tl = gsap.timeline();
        tl.to(['.modal__overlay', '.modal__container'], {opacity: 0, duration: 0.3})
        .to('.modal', {display: 'none', duration: 0})
    }

    return(
        <div className='modal'>
            <div className='modal__wrapper'>
            <div className='modal__overlay' onClick={closeOverlay}></div>
            <div className='modal__container'>
                <h2 className='modal__title'>Форма заказа</h2>
                <div className='modal__line'></div>

                <form ref={form} onSubmit={sendEmail} className='modal__form'>
                    <div className='modal__form_top'>

                    <div className='modal__form_item'>
                        <label className='modal__form_label' htmlFor='product'>ПРОДУКЦИЯ:</label>
                        <select name='product' className='modal__form_input modal__form_select' id='product'>
                        <option className='modal__form_placeholder' disabled>Выберите продукцию</option>
                            <option value='Мешок для фасовки угля'> Мешок для фасовки угля</option>
                            <option value='Мешок для фасовки цемента'>Мешок для фасовки цемента</option>
                            <option value='Мешок для фасовки пищевой продукции'>Мешок для фасовки пищевой продукции</option>
                        </select>
                    </div>

                    <div className='modal__form_item'>
                        <label className='modal__form_label' htmlFor='count'>КОЛИЧЕСТВО:</label>
                        <input name='count' placeholder='Выберите количество' className='modal__form_input' id='count' type='number'></input>
                    </div>

            
                    <div className='modal__form_item'>
                        <label className='modal__form_label' htmlFor='name'>ВАШЕ ИМЯ:</label>
                        <input name='name' placeholder='Введите ваше имя' className='modal__form_input' id='name' type='text'></input>
                    </div>
                    
                    </div>
                    <div className='modal__form_bottom'>

                    <div className='modal__form_item modal__form_bigTel'>
                        <label className='modal__form_label' htmlFor='tel'>ВАШ ТЕЛЕФОН:</label>
                        <input name='tel' placeholder='Введите номер' className='modal__form_input' id='tel' type='tel'></input>
                    </div> 

                    <div className='modal__form_item modal__form_big'>
                        <label className='modal__form_label' htmlFor='message'>СООБЩЕНИЕ:</label>
                        <textarea name='message' placeholder='Введите сообщение' className='modal__form_input modal__form_area' id='message'></textarea>
                    </div>
                    </div>

                    <input type='submit' className='modal__form_btn' value='Отправить'/>
                </form>
            </div>
            </div>
        </div>
    )
}

export default Modal;